import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {Container} from "reactstrap";
import PageTitle from "../components/pageTitle";

import iosPaso1 from "../assets/images/descarga-aplicacion/ios_paso_1.png";
import iosPaso2 from "../assets/images/descarga-aplicacion/ios_paso_2.png";
import androidPaso1 from "../assets/images/descarga-aplicacion/android_paso_1.png";
import androidPaso2 from "../assets/images/descarga-aplicacion/android_paso_2.png";


const NotFoundPage = () => (
  <Layout>
    <SEO title="Descarga la aplicación" />
    <Container className="py-5 mb-5">
      <PageTitle title="Descarga la aplicación"/>

      <div className="pt-3 pb-4">
        <h5 className="text-center font-weight-bold pb-3 pt-4">Dispositivos iOS</h5>
        <div className="d-flex flex-column flex-lg-row justify-content-center align-items-center align-items-lg-start">
          <div class="d-flex flex-column justify-content-center align-items-center kbus-instalacion">
            <img src={iosPaso1} className="my-3 mx-lg-3 w-75 kbus-img-shadow"  alt="Dirígete al site de Kbus desde Safari y pulsa en compartir" />
            <p className="text-center px-3">Dirígete al site de Kbus desde Safari y pulsa en compartir.</p>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center pt-5 pt-lg-0 kbus-instalacion">
            <img src={iosPaso2} className="my-3 mx-lg-3 w-75 kbus-img-shadow" alt="Selecciona añadir a la pantalla de inicio" />
            <p className="text-center px-3">Selecciona añadir a la pantalla de inicio.</p>
          </div>
        </div>
      </div>

      <PageTitle title=""/>

      <div className="pt-3">
        <h5 className="text-center font-weight-bold pb-3 pt-4">Dispositivos Android</h5>
        <div className="d-flex flex-column flex-lg-row justify-content-center align-items-center align-items-lg-start">
          <div className="d-flex flex-column justify-content-center align-items-center kbus-instalacion">
            <img src={androidPaso1} className="my-3 mx-lg-3 w-75 kbus-img-shadow" alt="Dirígete al site de Kbus y selecciona 'Añadir Kbus a la pantalla de inicio' en el banner inferior" />
            <p className="text-center px-3">Dirígete al site de Kbus y selecciona <i>Añadir Kbus a la pantalla de inicio</i> en el banner inferior.</p>
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center pt-5 pt-lg-0 kbus-instalacion">
            <img src={androidPaso2} className="my-3 mx-lg-3 w-75 kbus-img-shadow" alt="Selecciona 'Añadir'" />
            <p className="text-center px-3">Selecciona <i>Añadir</i>.</p>
          </div>
        </div>
      </div>
    </Container>
  </Layout>
)

export default NotFoundPage
